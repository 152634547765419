import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import Contact from './Contact'
import WriteUs from './WriteUs'
import photo from '../images/office-photo.png'
import arrow from '../images/arrow.png'
// import { useController } from 'react-scroll-parallax';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';


function Contacts({ contacts, checkData, isEmpty, onMove, mouseY, mouseX, posX, posY, onSubmit, openPopup }) {
    const mobile = window.matchMedia('(max-width: 1023px)').matches;
    React.useEffect(() =>{
        window.scrollTo(0, 0)
      }, [])
    // const sectionClassName = (location.pathname === )
    const lng = useContext(CurrentLangContext);
    React.useEffect(() => {
        checkData(contacts)

        return
    }, [checkData, contacts]);

    return (
        <>
            <main className="content">
                {isEmpty ? null :
                    <Contact contacts={contacts} checkData={checkData} onOpenMap={openPopup}
                    />
                }
                <WriteUs onMove={onMove} mouseX={mouseX} mouseY={mouseY} posX={posX} posY={posY}
                    onSubmit={onSubmit} />
                <div className='office'>
                    <div className='office__text'>
                        <span className='info-span info-span_place_office'>{locales[lng].contact_visit_us}</span>
                        {isEmpty? null :
                        <>
                        <p className='office__invite'>{contacts.Description[lng]}</p>
                        <p className='office__hours'>{contacts.Work_hours[lng]}</p>
                        </>
                        }
                    </div>
                    <img alt='our office' src={photo} className='office__photo'></img>

                </div>
                <div className='section__link-container section__link-container_contacts'>
                        <Link to="/about-us" className="section__link">{locales[lng].about_us}<img src={arrow} alt=""
                            className="section__link-img"></img></Link>
                    </div>
            </main>
        </>
    )
}

export default Contacts;
