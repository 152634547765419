import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import useFormValidation from '../utils/useFormValidation';
import RoundButton from './RoundButton';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';


function WriteUs({ onMove, mouseY, mouseX, posX, posY, onSubmit, className, isOpened }) {
    const lng = useContext(CurrentLangContext);
    const mobile = window.matchMedia('(max-width: 1300px)').matches;
    const [isChecked, setIsChecked] = React.useState(true);
    const { values, handleChange, errors, isValid } = useFormValidation({
        name: '',
        email: '',
        message: ''
    });
   
    function checking(e){
handleChange(e)
setIsChecked(true)
    }
    const style = {
        alignSelf: "flex-end"
    }
    
    function handleSubmit(e) {
        e.preventDefault();
        const { name, email, message } = values;
        onSubmit(name, email, message);
    }
    console.log(isOpened)

    return (
        <>
            <section className={`write-us ${isOpened ? 'write-us_popup' : ''}`}>
                <div className={`write-us__text ${isOpened ? 'write-us__text_popup' : ''} `}>
                    <h2 className='write-us__title'>{locales[lng].contact_form_word_1}</h2>
                    <h2 className='write-us__title write-us__title_translated'>{locales[lng].contact_form_word_2}</h2>
                    <span className={`info-span ${isOpened ? 'info-span_place_write-us_popup' : 'info-span_place_write-us'} `}>
                    {locales[lng].contact_description}</span>
                </div>
                <form className={`${isOpened ? 'write-us__form_popup' : 'write-us__form'}`} onSubmit={handleSubmit}>
                    <fieldset className='write-us__fieldset'>
                        <input onChange={handleChange} value={values.name} minLength='2' placeholder={`${locales[lng].contact_form_name} *`}
                            className={`write-us__input ${errors.name ? 'write-us__input_red' : ''}`}  type="name" id="name-input" name='name' autoComplete="off" required />
                        <span className="write-us__error">{errors.name}</span>

                        <input pattern='^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$'
                            onChange={handleChange} value={values.email} autoComplete="no" minLength='2' placeholder={`${locales[lng].contact_form_email} *`}
                            className={`write-us__input ${errors.email ? 'write-us__input_red' : ''}`} type="email" id="email-input" name='email' required />
                        <span className="write-us__error">{errors.email}</span>

                        <input onChange={handleChange} value={values.message} minLength='3' placeholder={`${locales[lng].contact_form_message} *`}
                            className={`write-us__input ${errors.message ? 'write-us__input_red' : ''}`}  type="message" id="message-input" name='message' autoComplete='no' required />
                        <span className="write-us__error">{errors.message}</span>
                        <div className='write-us__checkbox-zone'>
                            <input onChange={checking} className={`write-us__checkbox ${!isChecked ? 'write-us__checkbox_red' : ''}`}  type="checkbox" id="agree" name="agree" required />
                            <label className='write-us__checkbox-label' htmlFor="agree">{locales[lng].personal_data_agreement}</label>
                        </div>
                    </fieldset>

                    <RoundButton onMove={onMove} mouseY={mouseY} mouseX={mouseX} posX={posX} posY={posY} 
                    type='submit' contain={locales[lng].send} 
                    style={style} 
                    disabled={!isValid} onSubmit={handleSubmit} />
                </form>
            </section>
        </>
    )
}

export default WriteUs;
