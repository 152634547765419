import React, { useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import arrow from '../images/arrow.png'
import Card from './StoryCard';
import { Parallax } from 'react-scroll-parallax';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';


function Stories({ stories, checkData, isEmpty, }) {
    let { path } = useRouteMatch();
    const lng = useContext(CurrentLangContext);
    // console.log(stories)
    React.useEffect(() => {
        checkData(stories)

        return
    }, [checkData, stories]);

    const mobile = window.matchMedia('(max-width: 1024px)').matches;
    React.useEffect(() =>{
        window.scrollTo(0, 0)
      }, [])

    return (
        <main className="content">
            <section className="stories">
                <div className="section__container section__container_column">

                    <h2 className="section__title section__title_stories">{locales[lng].stories}</h2>
                    <p className='stories__span'>{locales[lng].stories_sub_title}</p>
                    {isEmpty ? null :
                        <>
                            <ul className='stories__list'>
                                {stories.map((i, id) => (
                                    <Card key={id} content={i} />
                                ))}
                            </ul>
                        </>
                    }

                    
                </div>
            </section>
            <div className='section__link-container'>
                        <Link to="/projects" className="section__link">{locales[lng].our_projects}<img src={arrow} alt=""
                            className="section__link-img"></img>
                        </Link>
                    </div>
        </main>

    )

}

export default Stories;
