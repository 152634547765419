import React, { useRef, useContext } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { gsap } from "gsap";
import { Linear } from 'gsap/all';
import { Link } from 'react-router-dom';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';

function Promo({ content, onOpenVideo, video }) {
    const [mouseX, setMouseX] = React.useState(0);
    const [mouseY, setMouseY] = React.useState(0);
    const [posX, setPosX] = React.useState(0);
    const [posY, setPosY] = React.useState(0);

    const showreelProject = content.find(item => item.Media.embed_html !== null)
    const mobile = window.matchMedia('(max-width: 1023px)').matches;
    const lng = useContext(CurrentLangContext);

    // console.log(showreel)
    const showreel = showreelProject.Media.find((i) => { return i.embed_html })
    // console.log(gg.embed_html)



    const linkToIframeStart = showreel.embed_html.indexOf('src="')
    const linkToIframe = showreel.embed_html.indexOf('" ', linkToIframeStart)
    const videolink = showreel.embed_html.slice(linkToIframeStart + 5, linkToIframe)
    console.log(videolink)

    function handleVideoImageClick() {
        onOpenVideo(videolink)
    }

    const cursor = React.useRef()
    const follower = React.useRef()
    // React.useEffect(() => {

    //     gsap.to(cursor.current, {
    //         repeat: -1,

    //         onRepeat: () => {

    //             setPosX(() => {
    //                 posX = (mouseX - posX) / 5})
    //             posY += (mouseY - posY) / 5
    //             gsap.set(cursor, {
    //                 css: {
    //                     left: mouseX,
    //                     top: mouseY
    //                 }
    //             })
    //             gsap.set(follower, {
    //                 css: {
    //                     left: posX - 24,
    //                     top: posY - 24
    //                 }
    //             })
    //         }
    //     })
    // })


    // console.log(Array.from(content.Name.eng))

    return (

        <section className="promo">
            <div className="section__container">

                <div className="promo__card promo__card_translate promo__card_project">
                    <Parallax y={[50, -40]}>
                        <div className="promo__card-text">
                            <h3 className="promo__card-header">{content[1].Name[lng]}</h3>
                            <span className="info-span">{locales[lng].new_work}</span>
                        </div>

                        <Link to={`/projects/${content[1].id}`} >
                            <img src={`https://beta.patterndigital.ru/api${content[1].Cover.url}`} alt="project" className="promo__card-img"></img>
                        </Link>
                    </Parallax>
                </div>


                <div

                    className="promo__card ">
                    {/* <div className="cursor cursor__follower" ref={follower} onMouseOver={mouseCoords}>

                        </div>
                        <div className="cursor cursor__inner" ref={cursor} ></div> */}
                    {/* <Parallax y={[-40, 40]} > */}
                    <h3 className="promo__card-header">{locales[lng].our_showreel}</h3>
                    {/* <Link to={`/projects/${showreel.id}`} > */}
                    <img

                        // video={video} 
                        onClick={handleVideoImageClick}
                        src={`https://beta.patterndigital.ru/api${showreelProject.Cover.url}`} alt="project" className="promo__card-img promo__card_video"></img>
                    {mobile ? <div className='promo__play-video-mobile' /> : null}
                    {/* </Link> */}
                    {/* </Parallax> */}
                </div>

            </div>
        </section>

    )

}

export default Promo;
