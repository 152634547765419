import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import arrow from '../images/arrow.png'
import Card from './ProjectsCard';
import { Parallax } from 'react-scroll-parallax';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';


function Projects({ content, scrollSize }) {

    // const arrayProjects = Object.keys(content).reduce((newContent, key) => {
    //     return [...newContent, {key: content[key]}]
    // }, [])
    // console.log(scrollSize)
    const cards = Array.from(content).slice(0, 4)
    const mobile = window.matchMedia('(max-width: 600px)').matches;
    const tablet = window.matchMedia('(min-width: 601px) and (max-width: 1023px)').matches;
    const lng = useContext(CurrentLangContext);
    const decorPlaceLeft = {
        top: `${scrollSize / 5 + 200}px`,
        left: '80px',
    }
    const decorPlaceRight = {
        bottom: `${scrollSize / 9}px`,
        right: "70px",
    }

    // let result = Object.entries(content);

    // console.log(typeof(arrayProjects))


    return (

        <section className="projects">
            <div className="section__container section__container_column">
                <div className="section__title-container section__title-container_projects-home">
                    <Parallax y={mobile ? [30, 0] : (tablet? ['0px', '-50px'] : [100, 0])} >
                        <h2 className="section__title">Projects</h2>
                    </Parallax>
                    <Parallax y={mobile ? ['-20px', '100px'] : (tablet? ['-20px', '200px'] : ['20px', '150px'])} >
                        <div className={`section__title-mask section__title-mask-projects`}></div>
                    </Parallax>
                </div>
                <span className="info-span info-span_place_projects">{locales[lng].featured_works}</span>
                {mobile ? null :
                    <div style={decorPlaceLeft} className='decorative-element'></div>}
                <ul>
                    {cards.map((i, id) => (
                        <Card key={id} content={i} link={`/projects/${i.id}`} />
                    ))}
                </ul>
                {mobile ? null :
                    <div style={decorPlaceRight} className="decorative-element"></div>}

                <Link to="/projects" className="section__link section__link_home">{locales[lng].see_more_projects}<img src={arrow} alt=""
                    className="section__link-img"></img></Link>

            </div>
        </section>


    )

}

export default Projects;
