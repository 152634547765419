import React, { useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';

function StoryCard({ content }) {
// const placingDate = content.Placing_date.substr(0, 7).replace('-', ' / ')
const placingDate = new Date(content.Placing_date)
const year = placingDate.getFullYear()
const lng = useContext(CurrentLangContext);
// let month = placingDate.getMonth()
let month
function pad(num, size) {
    num = num + 1
    num = num.toString();
    while (num.length < size) num = "0" + num;
    // console.log(num)
    return month = num;
  }
  pad(placingDate.getMonth(), 2)


    const mobile = window.matchMedia('(max-width: 1024px)').matches;


    return (
        content.Media_file && 
        <li className='stories__card'>
            <img className='stories__img' src={`https://beta.patterndigital.ru/api${content.Media_file.url}`} alt='' />
            <div className='stories__text'>
                <div className='stories__title'>
                    {content.Name && 
                <h3 className='stories__name'>{content.Name[lng]}</h3>
                }
                {placingDate && 
                <p className='stories__date'>{`${year} / ${month}`}</p>
                }
                </div>
                {content.Short_description && 
                <p className='stories__description'>{content.Short_description[lng]}</p>
                }
            </div>
        </li>
            
    )

}

export default StoryCard;
