import React, { useContext }  from 'react';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import defaultImage from '../images/photo-team-default.png'
import Button from './RoundButton'
import locales from '../constants/locales.json';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import arrow from '../images/arrow.png'
import Card from './ProjectsCard';
import { Parallax } from 'react-scroll-parallax';


function Project({ scrollSize, checkData, isEmpty, aboutUs, openPopup }) {
    let { path } = useRouteMatch();
    let { id } = useParams();
    const [services, setServices] = React.useState([]);
    const [clients, setClients] = React.useState([]);
    const [team, setTeam] = React.useState([]);
    const lng = useContext(CurrentLangContext);
    // const nameRU = aboutUs.Services.Name.rus
    // const nameEN = aboutUs.Services.Name.eng
    // const descriptionRU = aboutUs.Services.Short_description.rus
    // const descriptionEN = aboutUs.Services.Short_description.eng


    // const data = Array.from(aboutUs.Services)
   
console.log(aboutUs)

function handleOpenPopup(){
    openPopup()
}

React.useEffect(() =>{
    window.scrollTo(0, 0)
  }, [])

    React.useEffect(() => {
        checkData(aboutUs)
        if (!isEmpty) {
            setServices(Array.from(aboutUs.Services))
            setClients(Array.from(aboutUs.Clients))
            setTeam(Array.from(aboutUs.Team))
        }

        return
    }, [aboutUs, checkData, isEmpty]);

    const mobile = window.matchMedia('(max-width: 700px)').matches;
    const tablet = window.matchMedia('(max-width: 1700px)').matches;
    const style = {
        position: 'relative',
        top: `${tablet? 0 : 107}px`,
        right: `${mobile? 0 : 0}%`,
        marginLeft: `${mobile? 49 : tablet? 62 : 8}vw`
    }

    // let result = Object.entries(content);

    // console.log(typeof(arrayProjects))


    return (
        <main className="content">

            <div className="section__container section__container_column">
                {isEmpty ? null :
                    <>
                        <section className="about-us">
                            <article className='digital-first'>
                                <h1 className="section__title section__title_digital-first">{aboutUs.three_components.Head[lng]}</h1>

                                <div className='digital-first__text-container'>
                                {aboutUs.three_components.Left &&
                                    <p className='digital-first__text digital-first__text_left'>{aboutUs.three_components.Left[lng]}</p>
                                }
                                    {aboutUs.three_components.Right &&
                                                    (aboutUs.three_components.Right[lng].split('\n') || ' ').map((i, id) =>
                                                        <p key={id} className='digital-first__text digital-first__text_right'>{i}</p>)

                                                }
                                    {/* <p className='digital-first__text digital-first__text_right'>Lorem ipsum dolor sit amet,
                                        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                                        et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor
                                        in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                        Excepteur sint occaecat cupidatat non proident.</p>
                                    <p className='digital-first__text digital-first__text_right'>Commodo nulla facilisi nullam vehicula ipsum.
                                        Cursus sit amet dictum sit.Nunc sed augue lacus viverra vitae.Nunc mi ipsum faucibus vitae
                                        aliquet nec ullamcorper.Dictum fusce ut placerat orci nulla pellentesque dignissim.</p> */}
                                    <p className='digital-first__lead'>In nulla posuere sollicitudin aliquam ultrices
                                        sagittis orci a. At tempor commodo.</p>
                                </div>

                            </article>
                        </section>
                        <section className="services">
                            <div className='services__container'>
                                <div className='services__title-container'>
                                    <h2 className='services__title services__title_left'>{locales[lng].our_service_word_1}</h2>
                                    <h2 className='services__title services__title_right'>{locales[lng].our_service_word_2}</h2>
                                </div>
                            </div>
                            <span className='info-span info-span_place_services'>In nulla posuere sollicitudin
                                aliquam ultrices sagittis orci a. At tempor commodo.</span>
                            <ul className='services__list'>
                                <li className='services__item'>
                                    <p className='services__name'>{locales[lng].we_do}</p>
                                </li>
                                {services.map((i, id) => (

                                    <li className='services__item' key={id}>
                                        <p className='services__name'>{i.Name[lng]}</p>
                                        <span className='info-span info-span_place_services-list'>{i.Short_description[lng]}</span>
                                    </li>
                                ))}
                            </ul>


                        </section>
                        <section className="services">
                            <div className='services__container'>
                                <div className='services__title-container'>
                                    <h2 className='services__title services__title_left'>{locales[lng].our_clients_word_1}</h2>
                                    <h2 className='services__title services__title_right'>{locales[lng].our_clients_word_2}</h2>
                                </div>
                            </div>
                            <span className='info-span info-span_place_services'>In nulla posuere sollicitudin
                                aliquam ultrices sagittis orci a. At tempor commodo.</span>
                            <ul className='clients__list' >

                                {clients.map((i, id) => (
                                    <>
                                        <li className='clients__item' key={id}>
                                            <p className='clients__name'>{i.Name[lng]}</p>
                                            <span className='info-span info-span_place_clients-list'>Spaces and Places</span>
                                        </li>
                                    </>
                                ))}
                            </ul>


                        </section>
                        <section className="services" id='team'>
                            <div className='services__container'>
                                <div className='services__title-container'>
                                    <h2 className='services__title services__title_left'>{locales[lng].team}</h2>

                                </div>
                            </div>
                            <span className='info-span info-span_place_services'>In nulla posuere sollicitudin
                                aliquam ultrices sagittis orci a. At tempor commodo.</span>
                                <Button type='button' contain={locales[lng].write_us} style={style} onClick={handleOpenPopup} />
                            <ul className='team__list' >

                                {team.map((i, id) => (
                                    <>
                                        <li className='team__item' key={id}>
                                            <img src={i.Photo !== null ? `https://beta.patterndigital.ru/api${i.Photo.url}`
                                                :
                                                defaultImage} alt={i.Photo !== null ? `https://beta.patterndigital.ru/api${i.Photo.alternativeText}` : ``}
                                                className='team__photo' />
                                            <p className='team__name'>{i.Name[lng]}</p>
                                            <span className='info-span info-span_place_team-list'>{i.Position[lng]}</span>
                                        </li>
                                    </>
                                ))}
                            </ul>


                        </section>

                        <div className='section__link-container'>
                            <Link to="/contact" className="section__link">{locales[lng].contact}<img src={arrow} alt=""
                                className="section__link-img"></img></Link>
                        </div>
                    </>
                }
            </div>

        </main>

    )

}

export default Project;
