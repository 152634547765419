import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom'
import map from '../images/Map.png'
import { Parallax } from 'react-scroll-parallax';
// import { useController } from 'react-scroll-parallax';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';


function Contact({ contacts, checkData, onOpenMap }) {
    const mobile = window.matchMedia('(max-width: 1100px)').matches;
    const phone = contacts.Phone_number
    const email = contacts.Shown_email
    const address = contacts.Address
    // console.log(address)
    const location = useLocation();
    const lng = useContext(CurrentLangContext);

    React.useEffect(() => {
        checkData(contacts)

        return
    }, [checkData, contacts]);





    // const ParallaxCache = () => {
    //     const { parallaxController } = useController();

    //     React.useLayoutEffect(() => {
    //         // setTimeout(
    //     //     () => setScrollSize(window.pageYOffset), 
    //     //     500
    //     //   );
    //         const handler = () => parallaxController.update();
    //         window.addEventListener('load', handler);
    //         return () => window.removeEventListener('load', handler);
    //     }, [parallaxController]);

    //     return null;
    // };
    return (
        <section className={`${location.pathname === '/' ? 'contacts-home' : 'contacts'}`}>
            <div className="section__container section__container_column">
                <div className={`${location.pathname === '/' ? 'section__title-container_contacts-home' : 'section__title-container'}`}>
                    {location.pathname === '/' ?
                        <Parallax y={mobile ? [0, -100] : [40, 0]} >
                            <h2 className="section__title">{locales[lng].contact}</h2>
                        </Parallax>
                        :
                        <h2 className="section__title section__title_contacts">{locales[lng].contact}</h2>
                    }
                    {location.pathname === '/' ?
                        <Parallax y={mobile ? ['-50px', '10px'] : ['-150px', '110px']} >
                            <div className={`section__title-mask`}></div>
                        </Parallax>
                        :
                        null}

                </div>
                {location.pathname === '/' ?
                    <span className="info-span info-span_place_contacts">{locales[lng].contacts_description}</span>
                    : null}
                <div className="contacts__cards">
                    <div className="contacts__card contacts__card_phone">
                        <span className="info-span">{locales[lng].phone}</span>
                        {
                            <a href={`tel:${phone}`} className="contacts__data">
                                <p className="contacts__phone_left">{phone[1]}</p>
                                <p className="contacts__phone_center">{phone.substr(3, 3)} </p>
                                <p className="contacts__phone_center">{phone.substr(7, 9)}</p>
                            </a>
                        }
                    </div>
                    <div className="contacts__card contacts__card_black">
                        <span className="info-span">{locales[lng].email}</span>
                        <a href={`mailto:${email}`} className="contacts__data">
                            <p className="contacts__email_left">{email.substr(0, 4)}</p>
                            <p className="contacts__email_center">{email.substr(4, 14)}</p>
                            <p className="contacts__email_center">{email.substr(18, 3)}</p>
                        </a>
                        <span className="info-span info-span_place_email">{locales[lng].contacts_advantages}</span>
                    </div>
                    <div className="contacts__card contacts__card_address">
                        <span className="info-span">{locales[lng].address}</span>

                        <p className="contacts__address-text">{address[lng]}</p>
                        <img onClick={onOpenMap} src={map} alt="" className="contacts__address-map"></img>
                    </div>
                </div>
            </div>
        </section>

    )

}

export default Contact;
