import React from 'react';
import { Link } from 'react-router-dom';
import { gsap } from "gsap";




function RoundButton({ onMove, mouseY, mouseX, posX, posY, type, contain, style, disabled, onClick, onSubmit }) {
    // const [style, setStyle] = React.useState({
    //     // top: posY,
    //     // left: posX,
    //     bottom: 0,
    //     right: 0
    // });
    const follower = React.useRef()
    // function handleCursor(e) {
    //     onMove(e)

    // }
    // React.useEffect(() => {

    //     gsap.to({}, .1, {
    //         repeat: 1,
    //         duration: .1,
    //         ease: "elastic.out(1, 0.3)",
    //     //    onStart:() => { setStyle({
    //     //         top: `${posY /5}%`,
    //     //         left: `${posX /5 }%`
    //     //     })},
    //         onRepeat: () => {
    //             setStyle({
    //                 top: `${-36 + posY}px`,
    //                 left: `${550 + posX / 5}px`
    //             })
    //         },
    //         onComplete: () => {
    //             setStyle({
    //                 top: `0`,
    //                 left: `0`
    //             })
    //         }
    //         return
    //     })
    // gsap.fromTo({}, .01, {
    //         repeat: 1,


    //         onRepeat: () => {
    //             setStyle({
    //                 top: `${posY }px`,
    //                 left: `${posX }px`
    //             })
    //         }
    //     })



    //         return 
    // }, [mouseX, mouseY, posX, posY])


    return (
        <>

            {/* <div className='button__container'> */}
            {contain === 'Main page' ? <Link to='/' className='button__link' ><button type={type} ref={follower}
                style={style}
                className={disabled ? 'button button_disabled' : 'button'} disabled={disabled}>{contain}</button></Link>
                :
                <button type={type} ref={follower} 
                    style={style} onClick={onClick} onSubmit={onSubmit}
                    className={disabled ? 'button button_disabled' : 'button'} disabled={disabled}>{contain}</button>
            }

            {/* </div> */}
        </>
    )
}

export default RoundButton;



