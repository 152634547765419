import React, { useContext } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Route, Switch } from 'react-router-dom';
import api from '../utils/MainApi';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import Contacts from './Contacts'
import Projects from './Projects';
import NotFound from './NotFound';
import Project from './Project';
import AboutUs from './AboutUs';
import Stories from './Stories';
import WriteUsPopup from './WriteUsPopup';
import VideoPopup from './VideoPopup';
import MapPopup from './MapPopup';
import { useCookies } from 'react-cookie';


function App() {
  const [projectsInfo, setProjectsInfo] = React.useState({});
  const [contacts, setContacts] = React.useState({});
  const [currentLang, setCurrentLang] = React.useState('rus');
  const [isSentSucceed, setIsSentSucceed] = React.useState(false);
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [projectsNum, setProjectsNum] = React.useState([]);
  const [stories, setStories] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [aboutUs, setAboutUs] = React.useState([]);
  const [scrollSize, setScrollSize] = React.useState(0);
  const [scrollPrev, setScrollPrev] = React.useState(0);
  const [mobileHeader, setMobileHeader] = React.useState('');
  const [cookies, setCookie] = useCookies(['lng']);
  const [isWriteUsPopupOpened, setIsWriteUsPopupOpened] = React.useState(false);
  const [videoPopup, setVideoPopup] = React.useState('');
  const [isVideoPopupOpened, setIsVideoPopupOpened] = React.useState(false);
  const [isMapPopupOpened, setIsMapPopupOpened] = React.useState(false);

  const mobile = window.matchMedia('(max-width: 1023px)').matches;

  const onChangeLanguage = React.useCallback((newLng) => {
    setCookie('lng', newLng, { path: '/' });
    setCurrentLang(newLng)
  }, [setCookie])

  // function onChangeLanguage (newLng)  {
  //   setCookie('lng', newLng, { path: '/' });
  //   setCurrentLang(newLng)
  // }

  React.useEffect(() => {
    // const ff = localStorage.getItem('i18nextLng');
    console.log(cookies.lng)
    if (!cookies.lng) {
      const browserLanguage = window.navigator.language
      console.log(browserLanguage)
      if (browserLanguage === 'ru-RU') {
        onChangeLanguage('rus')
      } else {
        onChangeLanguage('eng')
      }
    }
    else {
      setCurrentLang(cookies.lng)
    }
  }, [cookies, onChangeLanguage])

  // console.log(cookies)
  // console.log(currentLang)

  React.useEffect(() => {
    Promise.all([api.getProjects(), api.getContacts(), api.getProjectsQuantity(), api.getStories()])
      .then(([projectsData, contactsData, projectsNum, storiesData]) => {
        setProjectsInfo(projectsData);
        setContacts(contactsData);
        setProjectsNum(projectsNum);
        setStories(storiesData.Stories)

      })
      .catch((e) => console.log(e));

    return
  }, []);

  React.useEffect(() => {
    api.getTags()
      .then((res) => {
        setTags(res)
      })
      .catch((e) => console.log(e));

    return
  }, []);

  React.useEffect(() => {
    api.getAboutUs()
      .then((res) => {
        setAboutUs(res)
      })
      .catch((e) => console.log(e));

    return
  }, []);
  // console.log(aboutUs)

  function checkData(data) {
    if (Object.keys(data).length === 0) {
      setIsEmpty(true)
      return
    }
    return setIsEmpty(false)

  }


  function offScroll(data) {
    if (data) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
    return
  }

  const [mouseX, setMouseX] = React.useState(0);
  const [mouseY, setMouseY] = React.useState(0);
  const [posX, setPosX] = React.useState(0);
  const [posY, setPosY] = React.useState(0);

  const mouseCoords = React.useCallback((e) => {
    setMouseX(e.clientX)
    setMouseY(e.clientY)
    // setPosX((mouseX) / 5)
    // setPosY((mouseY) / 5)
    // console.log(mouseX)
  }, [])


  // React.useEffect(() => {
  //   window.addEventListener('mousemove', mouseCoords)
  //   return () => window.removeEventListener('mousemove', mouseCoords)
  // }, [mouseCoords])


  // React.useEffect(() => {
  //   checkData(contacts)

  //   return
  // }, [contacts]);

  const onChange = React.useCallback(
    () => {
      setScrollSize(window.pageYOffset)

    },
    []
  )

  React.useEffect(() => {
    window.addEventListener('scroll', onChange);
    return () => window.removeEventListener("scroll", onChange);

  }, [onChange]);

  const handleScroll = React.useCallback(
    () => {
      if (mobile) {
        if (scrollSize > 67 && scrollSize > scrollPrev) {
          setMobileHeader('out')
        } else {
          setMobileHeader('')
        }
        setScrollPrev(scrollSize)
      } return
    }, [mobile, scrollPrev, scrollSize]
  )
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { capture: true })
    return () => window.removeEventListener("scroll", handleScroll, { capture: true });

  }, [handleScroll]);

  const handleSendEmail = (name, email, message) => {
    api.sendEmail(name, email, message)
      .then(() => {
        setIsSentSucceed(true)
      })
      .catch((err) => {
        console.error(err);
      });
  };





  const [hideHeader, setHideHeader] = React.useState(false);
  function hidenHeader(trigger) {
    setHideHeader(trigger)
  }
  function openWriteUsPopup() {
    setIsWriteUsPopupOpened(true)
  }
  function openVideoPopup(video) {
    setVideoPopup(video)
    console.log(video)
    setIsVideoPopupOpened(true)
  }
//   console.log(videoPopup)
// console.log(isVideoPopupOpened)

  function openMapPopup() {
    setIsMapPopupOpened(true)
  }
  function handleClosePopup(){
    setIsWriteUsPopupOpened(false)
    setIsMapPopupOpened(false)
    setIsVideoPopupOpened(false)
    setVideoPopup(null)
  }


  return (
    <ParallaxProvider>
      <CurrentLangContext.Provider value={currentLang}>
        {!currentLang ? null :
          <>
            {hideHeader ? null :

              <Header content={projectsInfo} stories={stories} checkData={checkData}
                isEmpty={isEmpty} scrollSize={scrollSize} scrollPrev={scrollPrev}
                mobileHeader={mobileHeader} offScroll={offScroll} changeLng={onChangeLanguage} />
            }
            <Switch>



              <Route exact path="/">

                <Home content={projectsInfo}
                  contacts={contacts}
                  checkData={checkData}
                  isEmpty={isEmpty}
                  projects={projectsNum}
                  scrollSize={scrollSize}
                  openVideoPopup={openVideoPopup}
                  openMapPopup={openMapPopup}
                  />


              </ Route>
              <Route path="/stories">
                <Stories stories={stories} checkData={checkData}
                  isEmpty={isEmpty} />
              </ Route>
              <Route path='/projects/:id'>
                {isEmpty ? null :
                  <Project
                    onMove={mouseCoords} mouseX={mouseX} mouseY={mouseY} posX={posX} posY={posY}
                    content={projectsInfo}
                    scrollSize={scrollSize}
                    checkData={checkData}
                    isEmpty={isEmpty}
                    tags={tags} />
                }

              </ Route>
              <Route path="/projects">
                <Projects
                  content={projectsInfo}
                  scrollSize={scrollSize}
                  checkData={checkData}
                  isEmpty={isEmpty}
                  tags={tags} />

              </ Route>
              <Route path="/contact">

                <Contacts contacts={contacts} checkData={checkData}
                  isEmpty={isEmpty}
                  onMove={mouseCoords} mouseX={mouseX} mouseY={mouseY} posX={posX} posY={posY}
                  onSubmit={handleSendEmail} 
                  openPopup={openMapPopup}/>


              </ Route>
              <Route path="/about-us">

                <AboutUs checkData={checkData}
                  isEmpty={isEmpty}
                  aboutUs={aboutUs}
                  openPopup={openWriteUsPopup}
                />
                {currentLang &&
                  <WriteUsPopup onSubmit={handleSendEmail} isOpened={isWriteUsPopupOpened} onClose={handleClosePopup} />}

              </ Route>



              <Route path="*">
                <NotFound test={hidenHeader} content={projectsInfo} stories={stories} checkData={checkData}
                  isEmpty={isEmpty}
                  onMove={mouseCoords} mouseX={mouseX} mouseY={mouseY} posX={posX} posY={posY}
                />
              </Route>


            </Switch>
            {hideHeader ? null :
            <Footer />
            }
            <VideoPopup video={videoPopup} isOpened={isVideoPopupOpened}
             onClose={handleClosePopup} /> 
             <MapPopup isOpened={isMapPopupOpened}
             onClose={handleClosePopup} />
          </>
        }

      </CurrentLangContext.Provider>
    </ParallaxProvider>
  );
}

export default App;
