import React, { useContext } from 'react';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import SimpleSlider from './Slider';
import arrow from '../images/arrow.png'
import playBtn from '../images/Play-btn1.svg'
import { gsap } from "gsap";
import Card from './ProjectsCard';
import { Parallax } from 'react-scroll-parallax';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';

function Project({ content, scrollSize, checkData, isEmpty, onMove, mouseX, mouseY }) {
    let { path } = useRouteMatch();
    let { id } = useParams();
    const [titleStyle, setTitleStyle] = React.useState({});
    const lng = useContext(CurrentLangContext);

    const elRef1 = React.useRef();
    const elRef2 = React.useRef();
    const elRef3 = React.useRef();
    const elRef4 = React.useRef();


    const mobile = window.matchMedia('(max-width: 1023px)').matches;
    const project = content.find((p) => { return (`${p.id}`) === id });
    // console.log(project)
    // console.log(project.Media)
    const followingProject = content.find((p) => { return (`${content.indexOf(p)}`) === (`${content.indexOf(project) + 1}`) });
    const nextProject = content.find((p) => { return (`${content.indexOf(p)}`) === (`${content.indexOf(project) + 2}`) });
    const previousProject = content.find((p) => { return (`${content.indexOf(p)}`) === (`${content.indexOf(project) - 1}`) });
    const backwardProject = content.find((p) => { return (`${content.indexOf(p)}`) === (`${content.indexOf(project) - 2}`) });
    // console.log(previousProject.id)
    // const right = project.Head.Right.eng.split('\n') || ' '
    // console.log(project.Media)
    const comakers = project.co_makers.map(i => i.logo.url);
    const isVideo = project.Media.find(item => item.embed_html !== null || undefined)
    const isVideoFile = project.Media.find(item => item.File.find(f => f.ext === '.mp4'))

    // console.log(width)
    // console.log(height)
    // console.log(isVideoFile)
    let video
    let video1
    let width
    let height
    const videoDetect = () => {
        if (isVideo) {

            const linkToIframeStart = isVideo.embed_html.indexOf('src="')
            const linkToIframe = isVideo.embed_html.indexOf('" ', linkToIframeStart)
            const widthStart = isVideo.embed_html.indexOf('width')
            const widthEnd = isVideo.embed_html.indexOf('" ', widthStart)
            width = isVideo.embed_html.slice(widthStart + 7, widthEnd)
            const heightStart = isVideo.embed_html.indexOf('height')
            const heightEnd = isVideo.embed_html.indexOf('" ', heightStart)
            height = isVideo.embed_html.slice(heightStart + 8, heightEnd)


            return video = isVideo.embed_html.slice(linkToIframeStart + 5, linkToIframe)
        }
        else if (isVideoFile) {
            return video1 = isVideoFile.File.map(f => f.url).toString()
        }

    }

    videoDetect()
    console.log(video)
    // console.log(isVideoFile.File.map(f => f.ext).toString())


    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [id])

    React.useEffect(() => {
        checkData(content)

        return
    }, [checkData, content]);

    const decorPlaceLeft = {
        top: `${3900 + scrollSize / 5}px`,
        left: '80px',
    }
    const decorPlaceRight = {
        top: `${4200 + scrollSize / 3}px`,
        right: "70px",
    }
    React.useEffect(() => {
        gsap.to(elRef1.current, {
            duration: 2, scaleY: 0, y: 80
        })
        gsap.fromTo(elRef2.current, { scaleY: .7, y: 20 }, { duration: 1, scaleY: 1, y: 0 })
    }, [])

    React.useEffect(() => {

        if (scrollSize > 650) {
            setTitleStyle({
                opacity: .1,
            })
        } else if (mobile && scrollSize > 550) {
            setTitleStyle({
                opacity: .1,
            })
        }
        else {
            setTitleStyle({})
        }

        return
    }, [mobile, scrollSize])
    // const [posX, setPosX] = React.useState(0);
    // const [posY, setPosY] = React.useState(0);
    // function handleMove(e) {

    //     onMove(e)
    // }

    // React.useEffect(() => {

    //     gsap.to({}, .01, {

    //         repeat: -1,

    //         onRepeat: () => {

    //             setPosX(posX + (mouseX - posX) / 10)
    //             setPosY(posY + (mouseY - posY) / 10)



    //             gsap.set(elRef3.current, {
    //                 css: {
    //                     left: posX,
    //                     top: posY
    //                 }
    //             })

    //         }

    //     })
    //     setPosX(mouseX)
    //     setPosY(mouseY)
    //     console.log(posX)
    //     console.log(mouseX)
    //     return
    // }, [])

    return (
        <main className="content">
            <section className="project">
                <div className="section__container section__container_column">
                    {isEmpty ? null :
                        <>
                            <h1 style={titleStyle} className="section__title section__title_project" ref={elRef2}>{project.Name[lng]}</h1>
                            <div className='section__title-mask_project' ref={elRef1}></div>
                            <div className='project__info'>
                                <div className='project__tags'>
                                    <span className='info-span'>{locales[lng].about_project}</span>
                                    <ul className='project_tag-list'>
                                        {project.tags.map((i, id) => (
                                            <li className='project_tag-item' key={id}>
                                                {i.Name[lng]}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <img className='project__cover' src={`https://beta.patterndigital.ru/api${project.Cover.url}`} alt="" />
                            </div>
                            {project.Head &&
                                <article className='project__description'>
                                    <div className='project__title-container'>
                                        <h2 className='project__about-title project__about-title_left'>{locales[lng].project_about_word_1}</h2>
                                        <h2 className='project__about-title project__about-title_right'>{locales[lng].project_about_word_2}</h2>
                                    </div>
                                    {(project.Head.Head || project.Head.Left || project.Head.Right) &&
                                        <>
                                            <p className='project__lead'>{project.Head.Head[lng]}</p>
                                            <div className='project__text-container'>
                                                {project.Head.Left &&
                                                    <p className='project__text project__text_left'>{project.Head.Left[lng]}</p>
                                                }
                                                {project.Head.Right &&
                                                    <div className='project__text_right-container'>
                                                        {project.Head.Right[lng].split('\n' || ' ').map((i, id) => (

                                                            <p key={id} className='project__text project__text_right'>{i}</p>
                                                        ))}
                                                    </div>
                                                }

                                            </div>
                                        </>
                                    }
                                    {project.client && project.Year && project.co_makers &&
                                        (mobile ?
                                            <div className='project__about-footer'>
                                                <span className='project__text-span'>{`${project.client.Name[lng]}`}</span>
                                                <span className='project__text-span'>{`${project.Year}`}</span>
                                                <span className='project__text-span'>{`${project.co_makers.map(i => ' ' + i.name[lng])}`}</span>
                                            </div>
                                            :
                                            <div className='project__about-footer'>
                                                <span className='project__text-span'>{`${locales[lng].client}: ${project.client.Name[lng]}`}</span>
                                                <span className='project__text-span'>{`${locales[lng].year}: ${project.Year}`}</span>
                                                <span className='project__text-span'>{`${locales[lng].collaborator} ${project.co_makers.map(i => ' ' + i.name.eng)}`}</span>
                                                <img className='project__comaker-logo' src={`https://beta.patterndigital.ru/api${comakers}`} alt='' />
                                            </div>)
                                    }
                                </article>
                            }
                            {project.Media &&
                                <ul className='project__media-list'>

                                    {isVideo && <li className='project__media-item project__media-video' >
                                        <div className='cursor' ref={elRef3}></div>
                                        <iframe title="This is a unique title" frameBorder="0" style={{ margin: 0 }} src={video}
                                            width={width} height={height} ></iframe>

                                        {mobile ? <img className='video-btn' src={playBtn} alt='' />
                                            : null}

                                    </li>
                                    }
                                    {isVideoFile &&
                                        <li className='project__media-item project__media-videofile' >
                                            <video src={`https://beta.patterndigital.ru/api${isVideoFile.File.map(f => f.url).toString()}`} width="640" height="360" controls />
                                            {mobile ? <img className='video-btn' src={playBtn} alt='' />
                                                : null}
                                        </li>
                                    }

                                    {project.Media.map((m, id) => {
                                        if (m.File.length === 1 && m.File.find((f) => { return f.ext.toString() !== '.mp4' })) {

                                            return <li className={`${m.size === 'large' ? 'project__media-item_column' : `media_position_${m.position}`} project__media-item`} key={id}>
                                                {(m.additional || m.Annotation) &&
                                                    <div className={m.size === 'large' ? 'project__text-container_top' : m.position === 'left' ? 'project__text-container_right' : 'project__text-container_left'}>
                                                        {m.additional && <span className='project__text-span'>{m.additional[lng]}</span>}
                                                        {m.Annotation &&
                                                            (m.Annotation[lng].split('\n') || ' ' || '/n/n').map((t, id) =>
                                                                <p key={id} className='media__text'>{t}</p>)}
                                                    </div>
                                                }
                                                {m.File.map((z, id) => {
                                                    if (z.ext.toString() !== `.mp4`) {
                                                        return <img className={`media_size_${m.size} `} key={id} src={`https://beta.patterndigital.ru/api${z.url}`}
                                                            alt=''></img>
                                                    }
                                                })
                                                }

                                            </li>
                                        } else if (m.File.length > 1) {

                                            return <li className={`project__media-item `} key={id}>

                                                <div className={m.size === 'large' ? 'project__text-container_top' : m.position === 'left' ? 'project__text-container_right' : 'project__text-container_left'}>
                                                    {m.additional && <span className='project__text-span'>{m.additional[lng]}</span>}
                                                    {m.Annotation &&
                                                        (m.Annotation[lng].split('\n') || ' ' || '/n/n').map((t, id) =>
                                                            <p key={id} className='media__text'>{t}</p>)
                                                    }
                                                </div>

                                                <div className={`project__media-container media_position_${m.position} media_size_${m.size}`}>
                                                    <SimpleSlider content={m.File} key={id} />
                                                </div>

                                            </li>
                                        }
                                        return null
                                    })}

                                </ul>
                            }
                            {project.Media.length > 5 &&
                                <>
                                    {mobile ? null :
                                        <div style={decorPlaceLeft} className='decorative-element'></div>}
                                    {mobile ? null :
                                        <div style={decorPlaceRight} className="decorative-element"></div>}

                                </>
                            }




                            <div className='section__link-container'>
                                <div className='project__title-container__bottom'>
                                    <h2 className='project__about-title project__about-title_left'>{locales[lng].more_projects_word_1}</h2>
                                    <h2 className='project__about-title project__about-title_right'>{locales[lng].more_projects_word_2}</h2>
                                </div>

                            </div>
                        </>
                    }
                    {/* <div className='section__link-container'>
                             <Link to="/stories" className="section__link">Our Stories<img src={arrow} alt=""
                                className="section__link-img"></img></Link>
                        </div> */}

                </div>
            </section>
            <ul className='project__cards'>
                {followingProject && nextProject ?
                    <>
                        <Card content={followingProject} link={`${followingProject.id}`} className={'project__card'} />
                        <Card content={nextProject} link={`${nextProject.id}`} className={'project__card'} />
                    </>
                    :
                    <>
                        <Card content={previousProject} link={`${previousProject.id}`} className={'project__card'} />
                        <Card content={backwardProject} link={`${backwardProject.id}`} className={'project__card'} />
                    </>
                }
            </ul>
        </main >

    )

}

export default Project;
