import React from "react";
import Slider from "react-slick";
import arrow from '../images/arrow.png';

function NextArrow(props) {
    const { onClick } = props;
    return (
        <div className={'slider__btn-next'} onClick={onClick}>
            <img src={arrow} className='slider__btn_next' alt=''></img>
        </div>
    );
}

function PrevArrow(props) {
    const { onClick } = props;
    return (
        <div className={'slider__btn-prev'} onClick={onClick}>
            <img src={arrow} className='slider__btn_prev' alt=''></img>
        </div>
    );
}
export default function SimpleSlider({ content }) {

    var settings = {
        className: 'slider',
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,

    };
    return (
        <Slider {...settings}>
            {content.map((i, id) =>

                <img style={{}} className={`project__img`} src={`https://beta.patterndigital.ru/api${i.url}`} alt='' key={id} ></img>

            )}
        </Slider>
    );
}