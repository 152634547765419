import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import uwalk from '../images/uwalk_footer.png';
import logo from '../images/logo.svg';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';



function Footer() {

    const lng = useContext(CurrentLangContext);
    return (
        <footer className="footer">

            <div className="footer__info">
                <img src={logo} alt="" className="footer__logo"></img>
                <p className="footer__year">&#169; {new Date().getFullYear()}</p>
            </div>
            <div className="footer__nav">
                <nav className="footer__social-media">
                    <span className="info-span info-span_place_footer">{locales[lng].follow_us}</span>
                    <a href="https://www.facebook.com/patterndigitalburo" target='_blank' className="footer__link" rel="noreferrer">Facebook</a>
                    <a href="https://instagram.com/everythingispattern?utm_medium=copy_link" target='_blank' className="footer__link" rel="noreferrer">Instagram</a>
                    {/* <Link to="facebook.com" className="footer__link">Twitter</Link> */}
                    <a href="https://www.facebook.com/patterndigitalburo" target='_blank' className="footer__link" rel="noreferrer">LinkedIn</a>
                </nav>
                <nav className="footer__social-media">
                    <span className="info-span info-span_place_footer">{locales[lng].we}</span>
                    <NavLink to="/careers" className="footer__link link_disabled">{locales[lng].careers}</NavLink>
                    <HashLink to="/about-us#team" className="footer__link">{locales[lng].team}</HashLink>
                    <NavLink to="/contact" className="footer__link">{locales[lng].contact}</NavLink>

                </nav>
                <div className="footer__moji">
                    <img src={uwalk} alt="" className="footer__moji-img"></img>
                    <NavLink to="/moji" className="footer__link">UWalk</NavLink>
                </div>
            </div>

        </footer>


    )

}

export default Footer;
