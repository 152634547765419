/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useContext } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';

gsap.registerPlugin(ScrollTrigger);

function Multimedia({ scrollSize, projectsNum, checkData }) {
    const mobile = window.matchMedia('(max-width: 700px)').matches;
    const tablet = window.matchMedia('(max-width: 1023px)').matches;
    const lng = useContext(CurrentLangContext);

    React.useEffect(() => {
        checkData(projectsNum)

        return
    }, [checkData, projectsNum]);


    const blackStyle = {
        background: '#131313',
        opacity: `${(scrollSize - 745) / 2000 + 0.6}`,
        width: '25%',
        height: `${(scrollSize - 745) / 100 * 50}px`,
        maxHeight: '100%',
    }
    const darkGreyStyle = {
        background: '#131313',
        opacity: `${(scrollSize - 745) / 2000 + 0.5}`,
        width: '25%',
        height: `${(scrollSize - 745) / 100 * 45}px`,
        maxHeight: '100%',
    }
    const greyStyle = {
        background: '#131313',
        opacity: `${(scrollSize - 745) / 2000 + 0.4}`,
        width: '25%',
        height: `${(scrollSize - 745) / 100 * 40}px`,
        maxHeight: '100%',
    }
    const lightGreyStyle = {
        background: '#131313',
        opacity: `${(scrollSize - 745) / 2000 + 0.2}`,
        width: '25%',
        height: `${(scrollSize - 745) / 100 * 35}px`,
        maxHeight: '100%',
    }
    
    const sizeL = {
        minHeight: `${tablet ? 250 : 450}px`,
        height: `${tablet ? 250 + (scrollSize - 1015) : 450 + (scrollSize - 1050) }px`,
        maxHeight: `${tablet ? 374 : 706}px`,

    }
    // console.log(scrollSize)

    const elRef1 = React.useRef()
    const elRef2 = React.useRef()
    const elRef3 = React.useRef()
    const elRef4 = React.useRef()
    // const elref5 = useRef();

   

    React.useEffect(() => {
        if (mobile && scrollSize === 870){
            gsap.to(elRef1.current, {
                duration: 1, scaleY: 0, y: 50
            })
            gsap.to(elRef2.current, {
                
                duration: 1, scaleY: 0, y: 120
            })
            gsap.to(elRef3.current, {
                duration: 1, scaleY: 0, y: 40
            })
            gsap.to(elRef4.current, {
                duration: 1, scaleY: 0, y: 7
            })
        }
        // else if (scrollSize === 1015) {

            gsap.to(elRef1.current, {
                scrollTrigger: {
                    trigger: ".multimedia__text",
                    start: "bottom bottom-=400px", // when the top of the trigger hits the top of the viewport
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                  },
                duration: 1, scaleY: 0, y: 50
            })
            gsap.to(elRef2.current, {
                scrollTrigger: {
                    trigger: ".multimedia__text",
                    start: "bottom bottom-=400px", // when the top of the trigger hits the top of the viewport
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                  },
                duration: 1, scaleY: 0, y: "40px"
            })
            gsap.to(elRef3.current, {
                scrollTrigger: {
                    trigger: ".multimedia__text",
                    start: "bottom bottom-=100px", // when the top of the trigger hits the top of the viewport
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                  },
                duration: .1, scaleY: 0, y: "40px"
            })
            gsap.to(elRef4.current, {
                scrollTrigger: {
                    trigger: ".multimedia__text",
                    start: "top bottom-=200px", // when the top of the trigger hits the top of the viewport
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                  },
                duration: .2, scaleY: 0, y: '20px'
            })

        
        return
    }, [scrollSize])

    return (
        <section className="multimedia">
            <div className="section__container section__container_column section__container_with-border">
                <div className="section__title-container section__title-container_multimedia">
                    {/* <Slide bottom > */}
                    <Parallax  y={mobile ? ['20px', '-30px'] : [50, 0]} >
                        <h2 className="section__title">{locales[lng].multimedia_word_1}</h2>
                    </Parallax>
                    
                    <Parallax y={mobile ? ['-30px', '100px'] : ['0px', '150px']} scaleY={[1, 0]}>
                    <div className={`section__title-mask`} ></div>
                    </Parallax>


                    <Parallax y={mobile ? ['0px', '-30px'] : [50, 0]} >
                        <h2 className="section__title">{locales[lng].multimedia_word_2}</h2>
                    </Parallax>
                    <Parallax y={mobile ? ['-20px', '100px']: ['-30px', '150px']} scaleY={[1, 0]} >
                        <div className={`section__title-mask`}></div>
                    </Parallax>
                </div>
                

                <span className="info-span info-span_place_multimedia">{locales[lng].multimedia_our_passion}</span>

                <div className="multimedia__content">
                    <div style={sizeL} className="multimedia__data">
                        <span className="info-span">{locales[lng].making_data_more}</span>
                        <p className="multimedia__text multimedia__text_translated">{locales[lng].readable}</p>
                        <div className={`multimedia__text_mask`} ref={elRef1} />
                        <p className="multimedia__text">{locales[lng].usable}</p>
                        <div className={`multimedia__text_mask`} ref={elRef2} />
                    </div>
                    <div className="multimedia__data">
                        <div className='multimedia__data-background'>
                            <div style={blackStyle} ></div>
                            <div style={darkGreyStyle} ></div>
                            <div style={greyStyle}></div>
                            <div style={lightGreyStyle}></div>
                        </div>
                        <span className="info-span">{locales[lng].more_than}</span>
                        <div className='gggg'>
                        <p className="multimedia__text multimedia__text_translated">{`${projectsNum.Project_count} projects`}</p>
                        <div className={`multimedia__text_mask`} ref={elRef4} />
                        <p className="multimedia__text">{locales[lng].executed}</p>
                       
                        <div className={`multimedia__text_mask`} ref={elRef3} />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )

}

export default Multimedia;
