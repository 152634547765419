import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { gsap } from "gsap";
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';

function Menu({ content, stories, checkData, isMenuOpen, onChoise, changeLng }) {
    const mobile = window.matchMedia('(max-width: 1023px)').matches;
    const menuVisibility = isMenuOpen ? 'menu_active' : '';
// const data = langs.lng


    React.useEffect(() => {
        checkData(stories)

        return
    }, [checkData, stories]);


    const lng = useContext(CurrentLangContext);
    // console.log(langs[lng])

    function handleChangeLng() {
        if (lng === 'eng'){
            changeLng('rus')
        } else {
            changeLng('eng')
        }
       
        
    }

    const elRef1 = React.useRef()
    const elRef2 = React.useRef()
    const elRef3 = React.useRef()
    const elRef4 = React.useRef()
    const elRef5 = React.useRef()
    const elRef6 = React.useRef()
    const elRef7 = React.useRef()
    const elRef8 = React.useRef()
    const tl = gsap.timeline();
    React.useEffect(() => {
        if (mobile && isMenuOpen) {

            gsap.fromTo(elRef1.current, { scaleY: 3, y: 10 }, { duration: 2, scaleY: 0, y: '20px' })
            gsap.fromTo(elRef2.current, { scaleY: 3, y: 10 }, { delay: .5, duration: 1, scaleY: 0, y: '20px' })
            gsap.fromTo(elRef3.current, { scaleY: 3, y: 15 }, { delay: .7, duration: 1, scaleY: 0, y: '20px' })
            gsap.fromTo(elRef4.current, { scaleY: 3, y: 15 }, { delay: .7, duration: 1, scaleY: 0, y: '20px' })
            gsap.fromTo(elRef5.current, { scaleY: 3, y: 15 }, { delay: 1, duration: 1, scaleY: 0, y: '20px' });
            gsap.fromTo('.menu__pages', { scaleY: .7, y: '-20px' }, { duration: 2, scaleY: 1, y: 0 });
            tl.fromTo('.menu__pages-masks', { scaleY: .7, y: '-20px' }, { duration: 3, scaleY: 1, y: '0' })
                .to('.menu__pages-masks', { duration: .1, scaleY: 0, y: 130 });
            gsap.fromTo(elRef6.current, { scaleY: 2, y: '250px' }, { delay: 1.5, duration: 2, scaleY: 0, y: '350px' });
            gsap.fromTo(elRef7.current, { y: -30 }, { duration: 2, y: 0 })
            gsap.fromTo(elRef8.current, { y: -30 }, { duration: 2, y: 0 })
            gsap.fromTo('.menu__social-mask', { y: -90, scaleY: 3 }, { duration: 2, scaleY: 0, y: -30 });
        } else if (isMenuOpen) {

            gsap.fromTo(elRef1.current, { scaleY: 3, y: 15 }, { duration: 2, scaleY: 0, y: '45px' })
            gsap.fromTo(elRef2.current, { scaleY: 3, y: 10 }, { delay: .5, duration: 1, scaleY: 0, y: '45px' })
            gsap.fromTo(elRef3.current, { scaleY: 3, y: 15 }, { delay: .7, duration: 1, scaleY: 0, y: '45px' })
            gsap.fromTo(elRef4.current, { scaleY: 3, y: 15 }, { delay: .7, duration: 1, scaleY: 0, y: '30px' })
            gsap.fromTo(elRef5.current, { scaleY: 3, y: 15 }, { delay: 1, duration: 1, scaleY: 0, y: '30px' });
            gsap.fromTo('.menu__pages', { scaleY: .7, y: '-100px' }, { duration: 3, scaleY: 1, y: 0 });
            tl.fromTo('.menu__pages-masks', { scaleY: .7, y: '-100px' }, { duration: 3, scaleY: 1, y: 0 })
                .to('.menu__pages-masks', { duration: .1, scaleY: 0, y: 130 });
            gsap.fromTo(elRef6.current, { scaleY: 2, y: -60 }, { delay: 1.5, duration: 2, scaleY: 0, y: '45px' });
            gsap.fromTo(elRef7.current, { y: -30 }, { duration: 2, y: 0 })
            gsap.fromTo(elRef8.current, { y: -30 }, { duration: 2, y: 0 })
            gsap.fromTo('.menu__social-mask', { y: -130, scaleY: 3 }, { duration: 2, scaleY: 0, y: -90 });
        }
        return

    }, [isMenuOpen, mobile, tl])



    return (
        <>
            <div onClick={onChoise} className={`menu ${menuVisibility}`}>
                <nav className='menu__internal'>
                    <div className='menu__pages' >
                        <NavLink exact to='/' activeClassName='menu__pages-link_active' className='menu__pages-link'>{locales[lng].homepage}</NavLink>
                        <div className="menu__projects">
                            <NavLink to='/projects' activeClassName='menu__pages-link_active' className='menu__pages-link'>{locales[lng].projects}<span className="menu__counter">{content.length}</span></NavLink>
                        </div>
                        <div className="menu__stories">
                            <NavLink to='/stories' activeClassName='menu__pages-link_active' className='menu__pages-link'>{locales[lng].stories}<span className="menu__counter">{stories.length}</span></NavLink>
                        </div>
                        <NavLink to='/about-us' activeClassName='menu__pages-link_active' className='menu__pages-link'>{locales[lng].about_us}</NavLink>
                        <NavLink to='/contact' activeClassName='menu__pages-link_active' className='menu__pages-link'>{locales[lng].contact}</NavLink>
                    </div>
                    <div className='menu__pages-masks'>
                        <div className='menu__pages-mask' ref={elRef1}></div>
                        <div className='menu__pages-mask' ref={elRef2}></div>
                        <div className='menu__pages-mask' ref={elRef3}></div>
                        <div className='menu__pages-mask' ref={elRef4}></div>
                        <div className='menu__pages-mask' ref={elRef5}></div>
                    </div>
                    <div className='menu__connect'>
                        <NavLink to='/contact' activeClassName='menu__pages-link_active' className='menu__connect-link'>{locales[lng].write_us}</NavLink>
                        <NavLink disabled to='/careers' activeClassName='menu__pages-link_active' className='menu__connect-link link_disabled'>{locales[lng].careers}</NavLink>
                        <HashLink to='/about-us#team'  className='menu__connect-link'>{locales[lng].team}</HashLink>
                    </div>
                    <div className='menu__footer'>
                        <p className="menu__footer-info">Pattern Digital &#169; {new Date().getFullYear()}</p>
                        <Link to='facebook.com' className='menu__footer-info'>{locales[lng].privacy_policy}</Link>
                        <button onClick={handleChangeLng} className='menu__footer-lang-btn'>{locales[lng].language_switch}</button>
                    </div>
                    <div className='menu__footer-mask' ref={elRef6} />
                    
                </nav>
                <nav className='menu_social'>
                    <a href='https://www.facebook.com/patterndigitalburo' target='_blank' className='menu__social-link' rel="noreferrer">Facebook</a>
                    <a href='https://instagram.com/everythingispattern?utm_medium=copy_link' target='_blank' className='menu__social-link' ref={elRef7} rel="noreferrer">Instagram</a>
                    <a href='facebook.com' className='menu__social-link' target='_blank' ref={elRef8}>LinkedIn</a>
                    <div className='menu__social-mask' ></div>
                    <div className='menu__social-mask' ></div>
                    <div className='menu__social-mask' ></div>
                </nav>
            </div>
        </>
    )

}

export default Menu;
