import React, { useContext } from 'react';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';
import playBtn from '../images/Play-btn1.svg'

function VideoPopup(props) {

    const mobile = window.matchMedia('(max-width: 1023px)').matches;


    return (
        <>
            <div className={`popup popup_color_black ${props.isOpened ? 'popup__opened' : ''}`} >
                <button type='button' className='popup__close-btn' onClick={props.onClose} />
                {/* <video src={props.video} width="640" height="360" controls /> */}
                <div className='popup__video'>
                <iframe className='video_cursor' title="This is a unique title" frameBorder="0" style={{ margin: 0 }} src={props.video}
                                            width='100%' height='100%' ></iframe>
                                            </div>
                {mobile ? props.isOpened ? null : <img className='video-btn' src={playBtn} alt='' />
                    : null}

            </div>

        </>
    )
}

export default VideoPopup;
