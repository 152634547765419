import React, { useContext, useRef } from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import DelayLink from 'react-delay-link';
import { gsap } from "gsap";


function ProjectCard({ content, link, className, scrollSize }) {
    const lng = useContext(CurrentLangContext);
    let { path } = useRouteMatch();
    const mobile = window.matchMedia('(max-width: 1023px)').matches;
    const tagsArray = content.tags
    const location = useLocation();
    const divStyle = {
        top: scrollSize,
    };
    // console.log(location.pathname)
    
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [maskClassName, setMaskClassName] = React.useState('');
    // const [imgClassName, setImgClassName] = React.useState('');
    // const [active, setActive] = React.useState(false);
    // const [imgStyle, setImgStyle] = React.useState({});


    // const elRef1 = useRef()
    // function handleCardClick(e) {
    //     setMaskClassName('_active')
    //     setActive(true)
    //     // console.log(e.getBoundingClientRect())
    // }

    // let rect
    // function handleLiClick(e) {
    //     rect = e.target.getBoundingClientRect();
    //     console.log(rect.top, rect.right, rect.bottom, rect.left);
    //     console.log(e.target.getBoundingClientRect())
    //     console.log(e.target)
    //     console.log(e.currentTarget)

    //     setImgStyle({
    //         top: rect.top,
    //         right: rect.right,
    //         bottom: rect.bottom,
    //         left: rect.left,
    //     });

    // }
    // React.useEffect(() => {
    //     gsap.fromTo(elRef1.current, 
    //         { scaleY: 1, y: 0 }, { duration: 2, scaleY: 1.3, y: 0, delay: 2 })
    // }, [active])



    return (

        <>

            <li className={`card__item projects__card ${className}`}>
            <div className={`card__background-color`} >
                            </div>
                <DelayLink delay={2000} to={link} replace={false}>
                    
                        
                            <img  src={`https://beta.patterndigital.ru/api${content.Cover.url}`} alt="" className={`card-img`}></img>
                            <div className={`projects__text projects__text${maskClassName}`}>

                                <h3 className="projects__card-header">{content.Name[lng]}</h3>
                                <div className={`projects__card-header_mask projects__card-header_mask${maskClassName}`} />


                                <span className="info-span">{`${tagsArray.map((i) => (
                                    (' ' + i.Name[lng])
                                ))}`}
                                </span>
                                <div className={`projects__info-span_mask projects__info-span_mask${maskClassName}`} />

                            </div>
                    {/* </Parallax> */}
                </DelayLink>
            </li>



        </>

    )

}

export default ProjectCard;
