class Api {
    constructor(options) {
        this._address = options.baseUrl;
        this._headers = options.headers;
    }
    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        }
        return Promise.reject(`${res.status}`);
    }
    getProjects() {
        return fetch(`${this._address}/projects`, {
            method: 'GET',

        })
            .then(this._checkResponse)
    }
    getProjectsQuantity() {
        return fetch(`${this._address}/projects-list`, {
            method: 'GET',

        })
            .then(this._checkResponse)
    }
    getStories() {
        return fetch(`${this._address}/stories-page`, {
            method: 'GET',

        })
            .then(this._checkResponse)
    }
    getClients() {
        return fetch(`${this._address}/clients`, {
            method: 'GET',

        })
            .then(this._checkResponse)
    }
    getEmployees() {
        return fetch(`${this._address}/employees`, {
            method: 'GET',

        })
            .then(this._checkResponse)
    }
    getTags() {
        return fetch(`${this._address}/tags`, {
            method: 'GET',

        })
            .then(this._checkResponse)
    }
    // getServices() {
    //     return fetch(`${this._address}/services`, {
    //         method: 'GET',

    //     })
    //         .then(this._checkResponse)
    // }
    getAboutUs() {
        return fetch(`${this._address}/about-us`, {
            method: 'GET',


        })
            .then(this._checkResponse)
    }
    getContacts() {
        return fetch(`${this._address}/contacts`, {
            method: 'GET',
        })
            .then(this._checkResponse)
    }
    sendEmail(data) {
        return fetch(`${this._address}/send`, {
            method: "POST",
            body: JSON.stringify({
                name: data.name,
                email: data.email,
                message: data.message
            }),
        }).then(this._checkResponse)
    }
}
const api = new Api({
    baseUrl: 'https://beta.patterndigital.ru/api',
    headers: {
        'Content-Type': 'application/json',
    },
})
export default api;