import React from 'react';
import Contacts from './Contact';
import Multimedia from './Multimedia';
import ProjectsHome from './ProjectsHome';
import Promo from './Promo'


function Home({ content, contacts, checkData, isEmpty, projects, scrollSize, openVideoPopup, openMapPopup, video }) {
    React.useEffect(() =>{
        window.scrollTo(0, 0)
      }, [])
    // console.log(scrollSize)

    return (
        <main className="content">
            {isEmpty ?
                null
                :
                <>
                    <Promo content={content} checkData={checkData} onOpenVideo={openVideoPopup} video={video}/>

                    <Multimedia scrollSize={scrollSize} projectsNum={projects} checkData={checkData} />

                    <ProjectsHome content={content} scrollSize={scrollSize} />

                    <Contacts contacts={contacts} checkData={checkData} onOpenMap={openMapPopup} />
                </>
            }
        </main>
    )

}

export default Home;
