import React, { useRef, useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';
import arrow from '../images/arrow.png'
import Card from './ProjectsCard';
import { Parallax } from 'react-scroll-parallax';


function Projects({ content, scrollSize, checkData, isEmpty, tags }) {
    let { path } = useRouteMatch();
    const lng = useContext(CurrentLangContext);
console.log(content)
  
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    React.useEffect(() => {
        checkData(content, tags)

        return
    }, [checkData, content, tags]);


    const [cards, setCards] = React.useState([]);
    React.useEffect(() => {
        setCards(content)
        return
    }, [content]);

    function handleCleanFilter(e) {
        setCards(content)
    }


    function handleTagFilter(e) {

        const filteredCards = content.filter(i => i.tags.find(r => r.Inside_name.includes(e.target.name, 0)))
        // console.log(e)
      
        console.log(filteredCards.map((i, id) => (
            <Card key={id} content={i} link={`${path}/${i.id}`} />
        )))
        setCards(filteredCards)
    
        e.target.focus({preventScroll:true})
        // e.target.className += ' projects__filter-btn_focused'
        // e.target.focus = true
        return 
        
    }
    
    function handleAntiFocus(e) {
        return false
     }
    function onBlur(e) {
        return false
       
    }
    // e.target.className = `${isSafari ? `projects__filter-btn-safari` : `projects__filter-btn`}`


    const mobile = window.matchMedia('(max-width: 1024px)').matches;

    const decorPlaceLeft = {
        top: `${923 + scrollSize / 5}px`,
        left: '80px',
    }
    const decorPlaceRight = {
        top: `${2200 + scrollSize / 3}px`,
        right: "70px",
    }

    // let result = Object.entries(content);

    // console.log(typeof(arrayProjects))

    const isSafari = window.navigator.userAgent.indexOf("Safari") > -1 && !window.navigator.userAgent.includes("Chrome")
    

    return (
        <main className="content">
            <section className="projects" onMouseDown={handleAntiFocus}>
                <div className="section__container section__container_column" onMouseDown={handleAntiFocus}>

                    <h2 className="section__title section__title_projects" onMouseDown={handleAntiFocus}>{locales[lng].projects}</h2>
                    {isEmpty ? null :
                        <>
                            <form className='projects__form'>
                                <fieldset className='projects__fieldset'>
                                    <button onClick={handleCleanFilter} className={`${isSafari ? `projects__filter-btn-safari` : `projects__filter-btn`}`} type='button' name='all' autoFocus>{locales[lng].all}</button>
                                    {tags.map((i, id) => (
                                        <button onClick={handleTagFilter} onBlur={onBlur} name={i.Inside_name} className={`${isSafari ? `projects__filter-btn-safari` : `projects__filter-btn`}`} type='button' key={id}>
                                            {i.Name[lng] + ` (${i.projects.length})`}</button>
                                    ))}
                                </fieldset>
                            </form>

                            {mobile ? null :
                                <div style={decorPlaceLeft} className='decorative-element'></div>}
                            <ul>
                                {cards.map((i, id) => (
                                    <Card key={id} content={i} link={`${path}/${i.id}`} scrollSize={scrollSize} />
                                ))}
                            </ul>
                        </>
                    }
                    {mobile ? null : cards.length < 5 ? null :
                        <div style={decorPlaceRight} className="decorative-element"></div>}
                    <div className='section__link-container'>
                        <Link to="/stories" className="section__link">{locales[lng].our_stories}<img src={arrow} alt=""
                            className="section__link-img"></img>
                        </Link>
                    </div>
                </div>
            </section>
        </main>

    )

}

export default Projects;
