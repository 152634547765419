import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.svg'
import Menu from './Menu'
import RoundButton from './RoundButton'
import { gsap } from "gsap";
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';



function NotFound({ content, stories, checkData, isEmpty, onMove, mouseY, mouseX, posX, posY, test }) {
    const mobile = window.matchMedia('(max-width: 1023px)').matches;
    const lng = useContext(CurrentLangContext);
    React.useEffect(() => {
        test(true)
        return () => test(false)
    }, []);

    // test(true)
    const [isMenuOpen, SetIsMenuOpen] = React.useState(false);
    function handleToggleMenu() {
        SetIsMenuOpen(!isMenuOpen);

    }
    const style = {
        position: 'absolute',
        bottom: `${mobile ? 5 : 5}%`,
        right: `${mobile ? 5 : 10}%`,
    }

    return (
        <>
            <Menu content={content} stories={stories} checkData={checkData}
                isEmpty={isEmpty} isMenuOpen={isMenuOpen} />
            <div className='error' >

                <div className='error__header'>
                    <Link to='/'> <img src={logo} alt="logo" className="error__logo"></img> </Link>
                    <button type='button' onClick={handleToggleMenu} className={`error__menu ${isMenuOpen ? 'error__menu_active' : ''}`}></button>
                </div>

                <div className='error__info'>

                    <p className='error__text'>{locales[lng].error}</p>
                    <p className='error__text'>404</p>
                </div>
                <div className='error__empty' />

                <RoundButton onMove={onMove} mouseY={mouseY} mouseX={mouseX} posX={posX} posY={posY} type='button' contain={locales[lng].main_page} style={style} />
            </div>
        </>
    )

}

export default NotFound;
