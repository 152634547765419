import React, { useContext }  from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../images/logo.svg'
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';
import Menu from './Menu'


function Header({ content, stories, checkData, isEmpty, mobileHeader, offScroll, changeLng }) {
    const mobile = window.matchMedia('(max-width: 1023px)').matches;
    const storiesNum = stories.length;
    const [isMenuOpen, SetIsMenuOpen] = React.useState(false);
    const lng = useContext(CurrentLangContext);
    // const [clientWidth, SetClientWidth] = React.useState('');
    // const [innerWidth, SetInnerWidth] = React.useState('');


    const handleToggleMenu = React.useCallback(() => {
        SetIsMenuOpen(!isMenuOpen);
    }, [isMenuOpen])




    // console.log(Object.keys(stories).length)
    React.useEffect(() => {
        checkData(stories)

        return
    }, [checkData, stories]);

    React.useEffect(() => {
        offScroll(isMenuOpen)

        return
    }, [isMenuOpen, offScroll]);



    return (
        <>

            <header className={`header ${mobileHeader}`} >
                <Menu content={content} stories={stories} checkData={checkData}
                    isEmpty={isEmpty} isMenuOpen={isMenuOpen} onChoise={handleToggleMenu} changeLng={changeLng}/>
                <div className='header__container'>
                    <Link to='/'> <img src={logo} alt="logo" className="header__logo"></img> </Link>
                    {mobile ? null :
                        <nav className="header__tabs">

                            {isMenuOpen ? null : <>
                                <div className="header__projects">
                                    <NavLink to="/projects" activeClassName='header__tab_active' className="header__tab header__tab_projects">{locales[lng].projects}</NavLink>
                                    {isEmpty ? "00" :
                                    <span className="header__counter">{content.length}</span>
                            }
                                </div>

                                <div className="header__stories">
                                    <NavLink to="/stories" activeClassName='header__tab_active' className="header__tab header__tab_stories">{locales[lng].stories}</NavLink>
                                    {isEmpty ? "00" :
                                        <span className="header__counter">{stories.length}</span>
                                    }
                                </div>

                                <NavLink to="/about-us" activeClassName='header__tab_active' className="header__tab header__tab__about-us">{locales[lng].about_us}</NavLink>
                                <NavLink to="/contact" activeClassName='header__tab_active' className="header__tab header__tab_contact">{locales[lng].contact}</NavLink>
                            </>}
                            <button type='button' onClick={handleToggleMenu} className={`header__menu ${isMenuOpen ? 'header__menu_active' : ''}`}></button>
                        </nav>
                    }

                </div>

            </header>
            {mobile ?
                <button type='button' onClick={handleToggleMenu} className={`header__menu ${isMenuOpen ? 'header__menu_active' : ''}`}></button>
                : null}
        </>

    )

}

export default Header;
