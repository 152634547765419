import React, { useContext } from 'react';
import { CurrentLangContext } from '../contexts/CurrentLanguageContext';
import locales from '../constants/locales.json';
import WriteUs from './WriteUs';


function WriteUsPopup({ onSubmit, isOpened, onClose }) {
  

    return (
        <>
        <div className={`popup popup_color_white ${isOpened? 'popup__opened' : ''}`} >
            <button type='button' className='popup__close-btn' onClick={onClose} />
        <WriteUs onSubmit={onSubmit} className={'write-us__popup'} isOpened={isOpened}/>

        </div>
           
        </>
    )
}

export default WriteUsPopup;
