import React from 'react';
import playBtn from '../images/Play-btn1.svg'
import map from '../images/map_big.png'

function MapPopup(props) {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const mobile = window.matchMedia('(max-width: 1023px)').matches;
    // console.log(props)

    return (
        <>
            <div className={`popup popup_color_white popup_place_map ${props.isOpened ? 'popup__opened' : ''}`} >
                <button type='button' className='popup__close-btn' onClick={props.onClose} />

                <div className='popup__map'>
                    <img src={map} alt='' className='popup__map-img'></img>
                    <div className='popup__point-aura'>
                    <div className='popup__point'></div>
                    </div>
                </div>


            </div>

        </>
    )
}

export default MapPopup;
